export type NameFormat = {
    nativeName: string;
    englishName: string;
};

export type LanguagesFormat = {
    languageShortCode: string;

    languages: NameFormat[];
};

export const LANGUAGES: LanguagesFormat[] = [
    {
        languageShortCode: 'ach',
        languages: [
            {
                nativeName: 'Lwo',
                englishName: 'Acholi',
            },
        ],
    },
    {
        languageShortCode: 'ady',
        languages: [
            {
                nativeName: 'Адыгэбзэ',
                englishName: 'Adyghe',
            },
        ],
    },
    {
        languageShortCode: 'af',
        languages: [
            {
                nativeName: 'Afrikaans',
                englishName: 'Afrikaans',
            },
        ],
    },
    {
        languageShortCode: 'af-NA',
        languages: [
            {
                nativeName: 'Afrikaans (Namibia)',
                englishName: 'Afrikaans (Namibia)',
            },
        ],
    },
    {
        languageShortCode: 'af-ZA',
        languages: [
            {
                nativeName: 'Afrikaans (South Africa)',
                englishName: 'Afrikaans (South Africa)',
            },
        ],
    },
    {
        languageShortCode: 'ak',
        languages: [
            {
                nativeName: 'Tɕɥi',
                englishName: 'Akan',
            },
        ],
    },
    {
        languageShortCode: 'ar',

        languages: [
            {
                nativeName: 'العربية',
                englishName: 'Arabic',
            },
        ],
    },
    {
        languageShortCode: 'ar-AR',
        languages: [
            {
                nativeName: 'العربية',
                englishName: 'Arabic',
            },
        ],
    },
    {
        languageShortCode: 'ar-MA',
        languages: [
            {
                nativeName: 'العربية',
                englishName: 'Arabic (Morocco)',
            },
        ],
    },
    {
        languageShortCode: 'ar-SA',

        languages: [
            {
                nativeName: 'العربية (السعودية)',
                englishName: 'Arabic (Saudi Arabia)',
            },
        ],
    },
    {
        languageShortCode: 'ay-BO',

        languages: [
            {
                nativeName: 'Aymar aru',
                englishName: 'Aymara',
            },
        ],
    },
    {
        languageShortCode: 'az',
        languages: [
            {
                nativeName: 'Azərbaycan dili',
                englishName: 'Azerbaijani',
            },
        ],
    },
    {
        languageShortCode: 'az-AZ',
        languages: [
            {
                nativeName: 'Azərbaycan dili',
                englishName: 'Azerbaijani',
            },
        ],
    },
    {
        languageShortCode: 'be-BY',
        languages: [
            {
                nativeName: 'Беларуская',
                englishName: 'Belarusian',
            },
        ],
    },
    {
        languageShortCode: 'bg',
        languages: [
            {
                nativeName: 'Български',
                englishName: 'Bulgarian',
            },
        ],
    },
    {
        languageShortCode: 'bg-BG',
        languages: [
            {
                nativeName: 'Български',
                englishName: 'Bulgarian',
            },
        ],
    },
    {
        languageShortCode: 'bn',

        languages: [
            {
                nativeName: 'বাংলা',
                englishName: 'Bengali',
            },
        ],
    },
    {
        languageShortCode: 'bn-IN',
        languages: [
            {
                nativeName: 'বাংলা (ভারত)',
                englishName: 'Bengali (India)',
            },
        ],
    },
    {
        languageShortCode: 'bn-BD',
        languages: [
            {
                nativeName: 'বাংলা(বাংলাদেশ)',
                englishName: 'Bengali (Bangladesh)',
            },
        ],
    },
    {
        languageShortCode: 'bs-BA',
        languages: [
            {
                nativeName: 'Bosanski',
                englishName: 'Bosnian',
            },
        ],
    },
    {
        languageShortCode: 'ca',

        languages: [
            {
                nativeName: 'Català',
                englishName: 'Catalan',
            },
        ],
    },
    {
        languageShortCode: 'ca-ES',
        languages: [
            {
                nativeName: 'Català',
                englishName: 'Catalan',
            },
        ],
    },
    {
        languageShortCode: 'cak',
        languages: [
            {
                nativeName: 'Maya Kaqchikel',
                englishName: 'Kaqchikel',
            },
        ],
    },
    {
        languageShortCode: 'ck-US',
        languages: [
            {
                nativeName: 'ᏣᎳᎩ (tsalagi)',
                englishName: 'Cherokee',
            },
        ],
    },
    {
        languageShortCode: 'cs',
        languages: [
            {
                nativeName: 'Čeština',
                englishName: 'Czech',
            },
        ],
    },
    {
        languageShortCode: 'cs-CZ',
        languages: [
            {
                nativeName: 'Čeština',
                englishName: 'Czech',
            },
        ],
    },
    {
        languageShortCode: 'cy',
        languages: [
            {
                nativeName: 'Cymraeg',
                englishName: 'Welsh',
            },
        ],
    },
    {
        languageShortCode: 'cy-GB',
        languages: [
            {
                nativeName: 'Cymraeg',
                englishName: 'Welsh',
            },
        ],
    },
    {
        languageShortCode: 'da',
        languages: [
            {
                nativeName: 'Dansk',
                englishName: 'Danish',
            },
        ],
    },
    {
        languageShortCode: 'da-DK',
        languages: [
            {
                nativeName: 'Dansk',
                englishName: 'Danish',
            },
        ],
    },
    {
        languageShortCode: 'de',
        languages: [
            {
                nativeName: 'Deutsch',
                englishName: 'German',
            },
        ],
    },
    {
        languageShortCode: 'de-AT',
        languages: [
            {
                nativeName: 'Deutsch (Österreich)',
                englishName: 'German (Austria)',
            },
        ],
    },
    {
        languageShortCode: 'de-DE',
        languages: [
            {
                nativeName: 'Deutsch (Deutschland)',
                englishName: 'German (Germany)',
            },
        ],
    },
    {
        languageShortCode: 'de-CH',
        languages: [
            {
                nativeName: 'Deutsch (Schweiz)',
                englishName: 'German (Switzerland)',
            },
        ],
    },
    {
        languageShortCode: 'dsb',
        languages: [
            {
                nativeName: 'Dolnoserbšćina',
                englishName: 'Lower Sorbian',
            },
        ],
    },
    {
        languageShortCode: 'el',
        languages: [
            {
                nativeName: 'Ελληνικά',
                englishName: 'Greek',
            },
        ],
    },
    {
        languageShortCode: 'el-GR',
        languages: [
            {
                nativeName: 'Ελληνικά',
                englishName: 'Greek (Greece)',
            },
        ],
    },
    {
        languageShortCode: 'en',
        languages: [
            {
                nativeName: 'English',
                englishName: 'English',
            },
        ],
    },
    {
        languageShortCode: 'en-GB',
        languages: [
            {
                nativeName: 'English (UK)',
                englishName: 'English (UK)',
            },
        ],
    },
    {
        languageShortCode: 'en-AU',
        languages: [
            {
                nativeName: 'English (Australia)',
                englishName: 'English (Australia)',
            },
        ],
    },
    {
        languageShortCode: 'en-CA',
        languages: [
            {
                nativeName: 'English (Canada)',
                englishName: 'English (Canada)',
            },
        ],
    },
    {
        languageShortCode: 'en-IE',
        languages: [
            {
                nativeName: 'English (Ireland)',
                englishName: 'English (Ireland)',
            },
        ],
    },
    {
        languageShortCode: 'en-IN',
        languages: [
            {
                nativeName: 'English (India)',
                englishName: 'English (India)',
            },
        ],
    },
    {
        languageShortCode: 'en-PI',
        languages: [
            {
                nativeName: 'English (Pirate)',
                englishName: 'English (Pirate)',
            },
        ],
    },
    {
        languageShortCode: 'en-UD',
        languages: [
            {
                nativeName: 'English (Upside Down)',
                englishName: 'English (Upside Down)',
            },
        ],
    },
    {
        languageShortCode: 'en-US',
        languages: [
            {
                nativeName: 'English (US)',
                englishName: 'English (US)',
            },
        ],
    },
    {
        languageShortCode: 'en-ZA',
        languages: [
            {
                nativeName: 'English (South Africa)',
                englishName: 'English (South Africa)',
            },
        ],
    },
    {
        languageShortCode: 'en@pirate',
        languages: [
            {
                nativeName: 'English (Pirate)',
                englishName: 'English (Pirate)',
            },
        ],
    },
    {
        languageShortCode: 'eo',
        languages: [
            {
                nativeName: 'Esperanto',
                englishName: 'Esperanto',
            },
        ],
    },
    {
        languageShortCode: 'eo-EO',
        languages: [
            {
                nativeName: 'Esperanto',
                englishName: 'Esperanto',
            },
        ],
    },
    {
        languageShortCode: 'es',
        languages: [
            {
                nativeName: 'Español',
                englishName: 'Spanish',
            },
        ],
    },
    {
        languageShortCode: 'es-AR',
        languages: [
            {
                nativeName: 'Español (Argentine)',
                englishName: 'Spanish (Argentina)',
            },
        ],
    },
    {
        languageShortCode: 'es-419',
        languages: [
            {
                nativeName: 'Español (Latinoamérica)',
                englishName: 'Spanish (Latin America)',
            },
        ],
    },
    {
        languageShortCode: 'es-CL',
        languages: [
            {
                nativeName: 'Español (Chile)',
                englishName: 'Spanish (Chile)',
            },
        ],
    },
    {
        languageShortCode: 'es-CO',
        languages: [
            {
                nativeName: 'Español (Colombia)',
                englishName: 'Spanish (Colombia)',
            },
        ],
    },
    {
        languageShortCode: 'es-EC',
        languages: [
            {
                nativeName: 'Español (Ecuador)',
                englishName: 'Spanish (Ecuador)',
            },
        ],
    },
    {
        languageShortCode: 'es-ES',
        languages: [
            {
                nativeName: 'Español (España)',
                englishName: 'Spanish (Spain)',
            },
        ],
    },
    {
        languageShortCode: 'es-LA',
        languages: [
            {
                nativeName: 'Español (Latinoamérica)',
                englishName: 'Spanish (Latin America)',
            },
        ],
    },
    {
        languageShortCode: 'es-NI',
        languages: [
            {
                nativeName: 'Español (Nicaragua)',
                englishName: 'Spanish (Nicaragua)',
            },
        ],
    },
    {
        languageShortCode: 'es-MX',
        languages: [
            {
                nativeName: 'Español (México)',
                englishName: 'Spanish (Mexico)',
            },
        ],
    },
    {
        languageShortCode: 'es-US',
        languages: [
            {
                nativeName: 'Español (Estados Unidos)',
                englishName: 'Spanish (United States)',
            },
        ],
    },
    {
        languageShortCode: 'es-VE',
        languages: [
            {
                nativeName: 'Español (Venezuela)',
                englishName: 'Spanish (Venezuela)',
            },
        ],
    },
    {
        languageShortCode: 'et',
        languages: [
            {
                nativeName: 'eesti keel',
                englishName: 'Estonian',
            },
        ],
    },
    {
        languageShortCode: 'et-EE',
        languages: [
            {
                nativeName: 'Eesti (Estonia)',
                englishName: 'Estonian (Estonia)',
            },
        ],
    },
    {
        languageShortCode: 'eu',
        languages: [
            {
                nativeName: 'Euskara',
                englishName: 'Basque',
            },
        ],
    },
    {
        languageShortCode: 'eu-ES',
        languages: [
            {
                nativeName: 'Euskara',
                englishName: 'Basque',
            },
        ],
    },
    {
        languageShortCode: 'fa',
        languages: [
            {
                nativeName: 'فارسی',
                englishName: 'Persian',
            },
        ],
    },
    {
        languageShortCode: 'fa-IR',
        languages: [
            {
                nativeName: 'فارسی',
                englishName: 'Persian',
            },
        ],
    },
    {
        languageShortCode: 'fb-LT',
        languages: [
            {
                nativeName: 'Leet Speak',
                englishName: 'Leet',
            },
        ],
    },
    {
        languageShortCode: 'ff',
        languages: [
            {
                nativeName: 'Fulah',
                englishName: 'Fulah',
            },
        ],
    },
    {
        languageShortCode: 'fi',
        languages: [
            {
                nativeName: 'Suomi',
                englishName: 'Finnish',
            },
        ],
    },
    {
        languageShortCode: 'fi-FI',
        languages: [
            {
                nativeName: 'Suomi',
                englishName: 'Finnish',
            },
        ],
    },
    {
        languageShortCode: 'fo-FO',
        languages: [
            {
                nativeName: 'Føroyskt',
                englishName: 'Faroese',
            },
        ],
    },
    {
        languageShortCode: 'fr',
        languages: [
            {
                nativeName: 'Français',
                englishName: 'French',
            },
        ],
    },
    {
        languageShortCode: 'fr-CA',
        languages: [
            {
                nativeName: 'Français (Canada)',
                englishName: 'French (Canada)',
            },
        ],
    },
    {
        languageShortCode: 'fr-FR',
        languages: [
            {
                nativeName: 'Français (France)',
                englishName: 'French (France)',
            },
        ],
    },
    {
        languageShortCode: 'fr-BE',
        languages: [
            {
                nativeName: 'Français (Belgique)',
                englishName: 'French (Belgium)',
            },
        ],
    },
    {
        languageShortCode: 'fr-CH',
        languages: [
            {
                nativeName: 'Français (Suisse)',
                englishName: 'French (Switzerland)',
            },
        ],
    },
    {
        languageShortCode: 'fy-NL',
        languages: [
            {
                nativeName: 'Frysk',
                englishName: 'Frisian (West)',
            },
        ],
    },
    {
        languageShortCode: 'ga',
        languages: [
            {
                nativeName: 'Gaeilge',
                englishName: 'Irish',
            },
        ],
    },
    {
        languageShortCode: 'ga-IE',
        languages: [
            {
                nativeName: 'Gaeilge (Gaelic)',
                englishName: 'Irish (Gaelic)',
            },
        ],
    },
    {
        languageShortCode: 'gl',
        languages: [
            {
                nativeName: 'Galego',
                englishName: 'Galician',
            },
        ],
    },
    {
        languageShortCode: 'gl-ES',
        languages: [
            {
                nativeName: 'Galego',
                englishName: 'Galician',
            },
        ],
    },
    {
        languageShortCode: 'gn-PY',
        languages: [
            {
                nativeName: "Avañe'ẽ",
                englishName: 'Guarani',
            },
        ],
    },
    {
        languageShortCode: 'gu-IN',
        languages: [
            {
                nativeName: 'ગુજરાતી',
                englishName: 'Gujarati',
            },
        ],
    },
    {
        languageShortCode: 'gx-GR',
        languages: [
            {
                nativeName: 'Ἑλληνική ἀρχαία',
                englishName: 'Classical Greek',
            },
        ],
    },
    {
        languageShortCode: 'he',
        languages: [
            {
                nativeName: 'עברית‏',
                englishName: 'Hebrew',
            },
        ],
    },
    {
        languageShortCode: 'he-IL',
        languages: [
            {
                nativeName: 'עברית‏',
                englishName: 'Hebrew',
            },
        ],
    },
    {
        languageShortCode: 'hi',
        languages: [
            {
                nativeName: 'हिन्दी',
                englishName: 'Hindi',
            },
        ],
    },
    {
        languageShortCode: 'hi-IN',
        languages: [
            {
                nativeName: 'हिन्दी',
                englishName: 'Hindi',
            },
        ],
    },
    {
        languageShortCode: 'hr',
        languages: [
            {
                nativeName: 'Hrvatski',
                englishName: 'Croatian',
            },
        ],
    },
    {
        languageShortCode: 'hr-HR',
        languages: [
            {
                nativeName: 'Hrvatski',
                englishName: 'Croatian',
            },
        ],
    },
    {
        languageShortCode: 'hsb',
        languages: [
            {
                nativeName: 'Hornjoserbšćina',
                englishName: 'Upper Sorbian',
            },
        ],
    },
    {
        languageShortCode: 'ht',
        languages: [
            {
                nativeName: 'Kreyòl',
                englishName: 'Haitian Creole',
            },
        ],
    },
    {
        languageShortCode: 'hu',
        languages: [
            {
                nativeName: 'Magyar',
                englishName: 'Hungarian',
            },
        ],
    },
    {
        languageShortCode: 'hu-HU',
        languages: [
            {
                nativeName: 'Magyar',
                englishName: 'Hungarian',
            },
        ],
    },
    {
        languageShortCode: 'hy-AM',
        languages: [
            {
                nativeName: 'Հայերեն',
                englishName: 'Armenian',
            },
        ],
    },
    {
        languageShortCode: 'id',
        languages: [
            {
                nativeName: 'Bahasa Indonesia',
                englishName: 'Indonesian',
            },
        ],
    },
    {
        languageShortCode: 'id-ID',
        languages: [
            {
                nativeName: 'Bahasa Indonesia',
                englishName: 'Indonesian',
            },
        ],
    },
    {
        languageShortCode: 'is',
        languages: [
            {
                nativeName: 'Íslenska',
                englishName: 'Icelandic',
            },
        ],
    },
    {
        languageShortCode: 'is-IS',
        languages: [
            {
                nativeName: 'Íslenska (Iceland)',
                englishName: 'Icelandic (Iceland)',
            },
        ],
    },
    {
        languageShortCode: 'it',
        languages: [
            {
                nativeName: 'Italiano',
                englishName: 'Italian',
            },
        ],
    },
    {
        languageShortCode: 'it-IT',
        languages: [
            {
                nativeName: 'Italiano',
                englishName: 'Italian',
            },
        ],
    },
    {
        languageShortCode: 'ja',
        languages: [
            {
                nativeName: '日本語',
                englishName: 'Japanese',
            },
        ],
    },
    {
        languageShortCode: 'ja-JP',
        languages: [
            {
                nativeName: '日本語',
                englishName: 'Japanese',
            },
        ],
    },
    {
        languageShortCode: 'jv-ID',
        languages: [
            {
                nativeName: 'Basa Jawa',
                englishName: 'Javanese',
            },
        ],
    },
    {
        languageShortCode: 'ka-GE',
        languages: [
            {
                nativeName: 'ქართული',
                englishName: 'Georgian',
            },
        ],
    },
    {
        languageShortCode: 'kk-KZ',
        languages: [
            {
                nativeName: 'Қазақша',
                englishName: 'Kazakh',
            },
        ],
    },
    {
        languageShortCode: 'km',
        languages: [
            {
                nativeName: 'ភាសាខ្មែរ',
                englishName: 'Khmer',
            },
        ],
    },
    {
        languageShortCode: 'km-KH',
        languages: [
            {
                nativeName: 'ភាសាខ្មែរ',
                englishName: 'Khmer',
            },
        ],
    },
    {
        languageShortCode: 'kab',
        languages: [
            {
                nativeName: 'Taqbaylit',
                englishName: 'Kabyle',
            },
        ],
    },
    {
        languageShortCode: 'kn',
        languages: [
            {
                nativeName: 'ಕನ್ನಡ',
                englishName: 'Kannada',
            },
        ],
    },
    {
        languageShortCode: 'kn-IN',
        languages: [
            {
                nativeName: 'ಕನ್ನಡ (India)',
                englishName: 'Kannada (India)',
            },
        ],
    },
    {
        languageShortCode: 'ko',
        languages: [
            {
                nativeName: '한국어',
                englishName: 'Korean',
            },
        ],
    },
    {
        languageShortCode: 'ko-KR',
        languages: [
            {
                nativeName: '한국어 (韩国)',
                englishName: 'Korean (Korea)',
            },
        ],
    },
    {
        languageShortCode: 'ku-TR',
        languages: [
            {
                nativeName: 'Kurdî',
                englishName: 'Kurdish',
            },
        ],
    },
    {
        languageShortCode: 'la',
        languages: [
            {
                nativeName: 'Latin',
                englishName: 'Latin',
            },
        ],
    },
    {
        languageShortCode: 'la-VA',
        languages: [
            {
                nativeName: 'Latin',
                englishName: 'Latin',
            },
        ],
    },
    {
        languageShortCode: 'lb',
        languages: [
            {
                nativeName: 'Lëtzebuergesch',
                englishName: 'Luxembourgish',
            },
        ],
    },
    {
        languageShortCode: 'li-NL',
        languages: [
            {
                nativeName: 'Lèmbörgs',
                englishName: 'Limburgish',
            },
        ],
    },
    {
        languageShortCode: 'lt',
        languages: [
            {
                nativeName: 'Lietuvių',
                englishName: 'Lithuanian',
            },
        ],
    },
    {
        languageShortCode: 'lt-LT',
        languages: [
            {
                nativeName: 'Lietuvių',
                englishName: 'Lithuanian',
            },
        ],
    },
    {
        languageShortCode: 'lv',
        languages: [
            {
                nativeName: 'Latviešu',
                englishName: 'Latvian',
            },
        ],
    },
    {
        languageShortCode: 'lv-LV',
        languages: [
            {
                nativeName: 'Latviešu',
                englishName: 'Latvian',
            },
        ],
    },
    {
        languageShortCode: 'mai',
        languages: [
            {
                nativeName: 'मैथिली, মৈথিলী',
                englishName: 'Maithili',
            },
        ],
    },
    {
        languageShortCode: 'mg-MG',
        languages: [
            {
                nativeName: 'Malagasy',
                englishName: 'Malagasy',
            },
        ],
    },
    {
        languageShortCode: 'mk',
        languages: [
            {
                nativeName: 'Македонски',
                englishName: 'Macedonian',
            },
        ],
    },
    {
        languageShortCode: 'mk-MK',
        languages: [
            {
                nativeName: 'Македонски (Македонски)',
                englishName: 'Macedonian (Macedonian)',
            },
        ],
    },
    {
        languageShortCode: 'ml',
        languages: [
            {
                nativeName: 'മലയാളം',
                englishName: 'Malayalam',
            },
        ],
    },
    {
        languageShortCode: 'ml-IN',
        languages: [
            {
                nativeName: 'മലയാളം',
                englishName: 'Malayalam',
            },
        ],
    },
    {
        languageShortCode: 'mn-MN',
        languages: [
            {
                nativeName: 'Монгол',
                englishName: 'Mongolian',
            },
        ],
    },
    {
        languageShortCode: 'mr',
        languages: [
            {
                nativeName: 'मराठी',
                englishName: 'Marathi',
            },
        ],
    },
    {
        languageShortCode: 'mr-IN',
        languages: [
            {
                nativeName: 'मराठी',
                englishName: 'Marathi',
            },
        ],
    },
    {
        languageShortCode: 'ms',
        languages: [
            {
                nativeName: 'Bahasa Melayu',
                englishName: 'Malay',
            },
        ],
    },
    {
        languageShortCode: 'ms-MY',
        languages: [
            {
                nativeName: 'Bahasa Melayu',
                englishName: 'Malay',
            },
        ],
    },
    {
        languageShortCode: 'mt',
        languages: [
            {
                nativeName: 'Malti',
                englishName: 'Maltese',
            },
        ],
    },
    {
        languageShortCode: 'mt-MT',
        languages: [
            {
                nativeName: 'Malti',
                englishName: 'Maltese',
            },
        ],
    },
    {
        languageShortCode: 'my',
        languages: [
            {
                nativeName: 'ဗမာစကာ',
                englishName: 'Burmese',
            },
        ],
    },
    {
        languageShortCode: 'no',
        languages: [
            {
                nativeName: 'Norsk',
                englishName: 'Norwegian',
            },
        ],
    },
    {
        languageShortCode: 'nb',
        languages: [
            {
                nativeName: 'Norsk (bokmål)',
                englishName: 'Norwegian (bokmal)',
            },
        ],
    },
    {
        languageShortCode: 'nb-NO',
        languages: [
            {
                nativeName: 'Norsk (bokmål)',
                englishName: 'Norwegian (bokmal)',
            },
        ],
    },
    {
        languageShortCode: 'ne',
        languages: [
            {
                nativeName: 'नेपाली',
                englishName: 'Nepali',
            },
        ],
    },
    {
        languageShortCode: 'ne-NP',
        languages: [
            {
                nativeName: 'नेपाली',
                englishName: 'Nepali',
            },
        ],
    },
    {
        languageShortCode: 'nl',
        languages: [
            {
                nativeName: 'Nederlands',
                englishName: 'Dutch',
            },
        ],
    },
    {
        languageShortCode: 'nl-BE',
        languages: [
            {
                nativeName: 'Nederlands (België)',
                englishName: 'Dutch (Belgium)',
            },
        ],
    },
    {
        languageShortCode: 'nl-NL',
        languages: [
            {
                nativeName: 'Nederlands (Nederland)',
                englishName: 'Dutch (Netherlands)',
            },
        ],
    },
    {
        languageShortCode: 'nn-NO',
        languages: [
            {
                nativeName: 'Norsk (nynorsk)',
                englishName: 'Norwegian (nynorsk)',
            },
        ],
    },
    {
        languageShortCode: 'oc',
        languages: [
            {
                nativeName: 'Occitan',
                englishName: 'Occitan',
            },
        ],
    },
    {
        languageShortCode: 'or-IN',
        languages: [
            {
                nativeName: 'ଓଡ଼ିଆ',
                englishName: 'Oriya',
            },
        ],
    },
    {
        languageShortCode: 'pa',
        languages: [
            {
                nativeName: 'ਪੰਜਾਬੀ',
                englishName: 'Punjabi',
            },
        ],
    },
    {
        languageShortCode: 'pa-IN',
        languages: [
            {
                nativeName: 'ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)',
                englishName: 'Punjabi (India)',
            },
        ],
    },
    {
        languageShortCode: 'pl',
        languages: [
            {
                nativeName: 'Polski',
                englishName: 'Polish',
            },
        ],
    },
    {
        languageShortCode: 'pl-PL',
        languages: [
            {
                nativeName: 'Polski',
                englishName: 'Polish',
            },
        ],
    },
    {
        languageShortCode: 'ps-AF',
        languages: [
            {
                nativeName: 'پښتو',
                englishName: 'Pashto',
            },
        ],
    },
    {
        languageShortCode: 'pt',
        languages: [
            {
                nativeName: 'Português',
                englishName: 'Portuguese',
            },
        ],
    },
    {
        languageShortCode: 'pt-BR',
        languages: [
            {
                nativeName: 'Português (Brasil)',
                englishName: 'Portuguese (Brazil)',
            },
        ],
    },
    {
        languageShortCode: 'pt-PT',
        languages: [
            {
                nativeName: 'Português (Portugal)',
                englishName: 'Portuguese (Portugal)',
            },
        ],
    },
    {
        languageShortCode: 'qu-PE',
        languages: [
            {
                nativeName: 'Qhichwa',
                englishName: 'Quechua',
            },
        ],
    },
    {
        languageShortCode: 'rm-CH',
        languages: [
            {
                nativeName: 'Rumantsch',
                englishName: 'Romansh',
            },
        ],
    },
    {
        languageShortCode: 'ro',
        languages: [
            {
                nativeName: 'Română',
                englishName: 'Romanian',
            },
        ],
    },
    {
        languageShortCode: 'ro-RO',
        languages: [
            {
                nativeName: 'Română',
                englishName: 'Romanian',
            },
        ],
    },
    {
        languageShortCode: 'ru',
        languages: [
            {
                nativeName: 'Русский',
                englishName: 'Russian',
            },
        ],
    },
    {
        languageShortCode: 'ru-RU',
        languages: [
            {
                nativeName: 'Русский',
                englishName: 'Russian',
            },
        ],
    },
    {
        languageShortCode: 'sa-IN',
        languages: [
            {
                nativeName: 'संस्कृतम्',
                englishName: 'Sanskrit',
            },
        ],
    },
    {
        languageShortCode: 'se-NO',
        languages: [
            {
                nativeName: 'Davvisámegiella',
                englishName: 'Northern Sámi',
            },
        ],
    },
    {
        languageShortCode: 'si-LK',
        languages: [
            {
                nativeName: 'පළාත',
                englishName: 'Sinhala (Sri Lanka)',
            },
        ],
    },
    {
        languageShortCode: 'sk',
        languages: [
            {
                nativeName: 'Slovenčina',
                englishName: 'Slovak',
            },
        ],
    },
    {
        languageShortCode: 'sk-SK',
        languages: [
            {
                nativeName: 'Slovenčina (Slovakia)',
                englishName: 'Slovak (Slovakia)',
            },
        ],
    },
    {
        languageShortCode: 'sl',
        languages: [
            {
                nativeName: 'Slovenščina',
                englishName: 'Slovenian',
            },
        ],
    },
    {
        languageShortCode: 'sl-SI',
        languages: [
            {
                nativeName: 'Slovenščina',
                englishName: 'Slovenian',
            },
        ],
    },
    {
        languageShortCode: 'so-SO',
        languages: [
            {
                nativeName: 'Soomaaliga',
                englishName: 'Somali',
            },
        ],
    },
    {
        languageShortCode: 'sq',
        languages: [
            {
                nativeName: 'Shqip',
                englishName: 'Albanian',
            },
        ],
    },
    {
        languageShortCode: 'sq-AL',
        languages: [
            {
                nativeName: 'Shqip',
                englishName: 'Albanian',
            },
        ],
    },
    {
        languageShortCode: 'sr',
        languages: [
            {
                nativeName: 'Српски',
                englishName: 'Serbian',
            },
        ],
    },
    {
        languageShortCode: 'sr-RS',
        languages: [
            {
                nativeName: 'Српски (Serbia)',
                englishName: 'Serbian (Serbia)',
            },
        ],
    },
    {
        languageShortCode: 'su',
        languages: [
            {
                nativeName: 'Basa Sunda',
                englishName: 'Sundanese',
            },
        ],
    },
    {
        languageShortCode: 'sv',
        languages: [
            {
                nativeName: 'Svenska',
                englishName: 'Swedish',
            },
        ],
    },
    {
        languageShortCode: 'sv-SE',
        languages: [
            {
                nativeName: 'Svenska',
                englishName: 'Swedish',
            },
        ],
    },
    {
        languageShortCode: 'sw',
        languages: [
            {
                nativeName: 'Kiswahili',
                englishName: 'Swahili',
            },
        ],
    },
    {
        languageShortCode: 'sw-KE',
        languages: [
            {
                nativeName: 'Kiswahili',
                englishName: 'Swahili (Kenya)',
            },
        ],
    },
    {
        languageShortCode: 'ta',
        languages: [
            {
                nativeName: 'தமிழ்',
                englishName: 'Tamil',
            },
        ],
    },
    {
        languageShortCode: 'ta-IN',
        languages: [
            {
                nativeName: 'தமிழ்',
                englishName: 'Tamil',
            },
        ],
    },
    {
        languageShortCode: 'te',
        languages: [
            {
                nativeName: 'తెలుగు',
                englishName: 'Telugu',
            },
        ],
    },
    {
        languageShortCode: 'te-IN',
        languages: [
            {
                nativeName: 'తెలుగు',
                englishName: 'Telugu',
            },
        ],
    },
    {
        languageShortCode: 'tg',
        languages: [
            {
                nativeName: 'забо́ни тоҷикӣ́',
                englishName: 'Tajik',
            },
        ],
    },
    {
        languageShortCode: 'tg-TJ',
        languages: [
            {
                nativeName: 'тоҷикӣ',
                englishName: 'Tajik',
            },
        ],
    },
    {
        languageShortCode: 'th',
        languages: [
            {
                nativeName: 'ภาษาไทย',
                englishName: 'Thai',
            },
        ],
    },
    {
        languageShortCode: 'th-TH',
        languages: [
            {
                nativeName: 'ภาษาไทย (ประเทศไทย)',
                englishName: 'Thai (Thailand)',
            },
        ],
    },
    {
        languageShortCode: 'tl',
        languages: [
            {
                nativeName: 'Filipino',
                englishName: 'Filipino',
            },
        ],
    },
    {
        languageShortCode: 'tl-PH',
        languages: [
            {
                nativeName: 'Filipino',
                englishName: 'Filipino',
            },
        ],
    },
    {
        languageShortCode: 'tlh',
        languages: [
            {
                nativeName: 'tlhIngan-Hol',
                englishName: 'Klingon',
            },
        ],
    },
    {
        languageShortCode: 'tr',
        languages: [
            {
                nativeName: 'Türkçe',
                englishName: 'Turkish',
            },
        ],
    },
    {
        languageShortCode: 'tr-TR',
        languages: [
            {
                nativeName: 'Türkçe',
                englishName: 'Turkish',
            },
        ],
    },
    {
        languageShortCode: 'tt-RU',
        languages: [
            {
                nativeName: 'татарча',
                englishName: 'Tatar',
            },
        ],
    },
    {
        languageShortCode: 'uk',
        languages: [
            {
                nativeName: 'Українська',
                englishName: 'Ukrainian',
            },
        ],
    },
    {
        languageShortCode: 'uk-UA',
        languages: [
            {
                nativeName: 'Українська',
                englishName: 'Ukrainian',
            },
        ],
    },
    {
        languageShortCode: 'ur',
        languages: [
            {
                nativeName: 'اردو',
                englishName: 'Urdu',
            },
        ],
    },
    {
        languageShortCode: 'ur-PK',
        languages: [
            {
                nativeName: 'اردو',
                englishName: 'Urdu',
            },
        ],
    },
    {
        languageShortCode: 'uz',
        languages: [
            {
                nativeName: "O'zbek",
                englishName: 'Uzbek',
            },
        ],
    },
    {
        languageShortCode: 'uz-UZ',
        languages: [
            {
                nativeName: "O'zbek",
                englishName: 'Uzbek',
            },
        ],
    },
    {
        languageShortCode: 'vi',
        languages: [
            {
                nativeName: 'Tiếng Việt',
                englishName: 'Vietnamese',
            },
        ],
    },
    {
        languageShortCode: 'vi-VN',
        languages: [
            {
                nativeName: 'Tiếng Việt',
                englishName: 'Vietnamese',
            },
        ],
    },
    {
        languageShortCode: 'xh-ZA',
        languages: [
            {
                nativeName: 'isiXhosa',
                englishName: 'Xhosa',
            },
        ],
    },
    {
        languageShortCode: 'yi',
        languages: [
            {
                nativeName: 'ייִדיש',
                englishName: 'Yiddish',
            },
        ],
    },
    {
        languageShortCode: 'yi-DE',
        languages: [
            {
                nativeName: 'ייִדיש (German)',
                englishName: 'Yiddish (German)',
            },
        ],
    },
    {
        languageShortCode: 'zh',
        languages: [
            {
                nativeName: '中文',
                englishName: 'Chinese',
            },
        ],
    },
    {
        languageShortCode: 'zh-Hans',
        languages: [
            {
                nativeName: '中文简体',
                englishName: 'Chinese Simplified',
            },
        ],
    },
    {
        languageShortCode: 'zh-Hant',
        languages: [
            {
                nativeName: '中文繁體',
                englishName: 'Chinese Traditional',
            },
        ],
    },
    {
        languageShortCode: 'zh-CN',
        languages: [
            {
                nativeName: '中文（中国）',
                englishName: 'Chinese Simplified (China)',
            },
        ],
    },
    {
        languageShortCode: 'zh-HK',
        languages: [
            {
                nativeName: '中文（香港）',
                englishName: 'Chinese Traditional (Hong Kong)',
            },
        ],
    },
    {
        languageShortCode: 'zh-SG',
        languages: [
            {
                nativeName: '中文（新加坡）',
                englishName: 'Chinese Simplified (Singapore)',
            },
        ],
    },
    {
        languageShortCode: 'zh-TW',

        languages: [
            {
                nativeName: '中文（台灣）',
                englishName: 'Chinese Traditional (Taiwan)',
            },
        ],
    },
    {
        languageShortCode: 'zu-ZA',

        languages: [
            {
                nativeName: 'isiZulu',
                englishName: 'Zulu',
            },
        ],
    },
];

/**
add rtl prop to list
*/

type LanguageType = {
    nativeName: string;
    englishName: string;
};

export const getNativeName = (lang: string): string => {
    const languageMatch = !!LANGUAGES && ((LANGUAGES as any)[lang] as LanguageType);

    if (languageMatch) {
        return languageMatch.nativeName;
    } else {
        // if we don't have it
        return lang;
    }
};
